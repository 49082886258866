@import '@~styles/custom-variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.root {
  position: relative;
  width: 100%;

  svg {
    width: 100%;
    height: auto;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

@include media-breakpoint-up(md) {

  .roadMapList {
    position: absolute;
    padding-left: 0;

    li {
      margin-bottom: 0.65rem;
    }
  }

  :global(#list-1) {
    top: 18.6%;
    left: 77.7%;
    font-size: 85%;
    width: 18%;
  }

  :global(#list-2) {
    top: 18.6%;
    left: 61.5%;
    font-size: 85%;
    width: 12.5%;
  }

  :global(#list-3) {
    top: 18.6%;
    left: 45.3%;
    font-size: 85%;
    width: 13%;
  }

  :global(#list-4) {
    top: 18.6%;
    left: 28.9%;
    font-size: 85%;
    width: 14%;
  }

  :global(#list-5) {
    top: 18.6%;
    left: 12.7%;
    font-size: 85%;
    width: 13%;
  }

  :global(#list-6) {
    top: 79.2%;
    left: 12.7%;
    font-size: 85%;
    width: 14%;
  }

  :global(#list-7) {
    top: 79.2%;
    left: 28.9%;
    font-size: 85%;
    width: 14%;
  }

  :global(#list-8) {
    top: 79.2%;
    left: 45.3%;
    font-size: 85%;
    width: 14%;
  }

  :global(#list-9) {
    top: 79.2%;
    left: 61.5%;
    font-size: 85%;
    width: 14%;
  }

  :global(#list-10) {
    top: 79.2%;
    left: 77.7%;
    font-size: 85%;
    width: 12%;
  }
}
