@import '@~styles/custom-variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

.root {
  position: relative;
}

@include media-breakpoint-up(md) {

  .root {
    position: absolute;
  }

  :global(#road-map-q1) {
    top: 0;
    left: 76.6%;
  }

  :global(#road-map-q2) {
    top: 0;
    left: 60.3%;
  }

  :global(#road-map-q3) {
    top: 0;
    left: 44%;
  }

  :global(#road-map-q4) {
    top: 0;
    left: 28%;
  }

  :global(#road-map-q5) {
    top: 0;
    left: 11.6%;
  }

  :global(#road-map-q6) {
    top: 60%;
    left: 11.6%;
  }

  :global(#road-map-q7) {
    top: 60%;
    left: 28%;
  }

  :global(#road-map-q8) {
    top: 60%;
    left: 44%;
  }

  :global(#road-map-q9) {
    top: 60%;
    left: 60.3%;
  }

  :global(#road-map-q10) {
    top: 60%;
    left: 76.6%;
  }
}
