@font-face {
  font-family: 'MazzardM';
  src:
    url('@~assets/fonts/mazzard/regular/MazzardM-Regular.woff2') format('WOFF2'),
    url('@~assets/fonts/mazzard/regular/MazzardM-Regular.woff') format('WOFF'),
    url('@~assets/fonts/mazzard/regular/MazzardM-Regular.eot') format('EOT'),
    url('@~assets/fonts/mazzard/regular/MazzardM-Regular.svg') format('SVG');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'MazzardM';
  src:
    url('@~assets/fonts/mazzard/bold/MazzardM-Bold.woff2') format('WOFF2'),
    url('@~assets/fonts/mazzard/bold/MazzardM-Bold.woff') format('WOFF'),
    url('@~assets/fonts/mazzard/bold/MazzardM-Bold.eot') format('EOT'),
    url('@~assets/fonts/mazzard/bold/MazzardM-Bold.svg') format('SVG');
  font-weight: bold;
}

@font-face {
  font-family: 'MazzardM';
  src:
    url('@~assets/fonts/mazzard/italic/MazzardM-Italic.woff2') format('WOFF2'),
    url('@~assets/fonts/mazzard/italic/MazzardM-Italic.woff') format('WOFF'),
    url('@~assets/fonts/mazzard/italic/MazzardM-Italic.eot') format('EOT'),
    url('@~assets/fonts/mazzard/italic/MazzardM-Italic.svg') format('SVG');
  font-style: italic;
}

@font-face {
  font-family: 'MazzardM-Light';
  src:
    url('@~assets/fonts/mazzard/light/MazzardM-Light.woff2') format('WOFF2'),
    url('@~assets/fonts/mazzard/light/MazzardM-Light.woff') format('WOFF'),
    url('@~assets/fonts/mazzard/light/MazzardM-Light.eot') format('EOT'),
    url('@~assets/fonts/mazzard/light/MazzardM-Light.svg') format('SVG');
}

@font-face {
  font-family: 'MazzardM-LightItalic';
  src:
    url('@~assets/fonts/mazzard/light-italic/MazzardM-LightItalic.woff2') format('WOFF2'),
    url('@~assets/fonts/mazzard/light-italic/MazzardM-LightItalic.woff') format('WOFF'),
    url('@~assets/fonts/mazzard/light-italic/MazzardM-LightItalic.eot') format('EOT'),
    url('@~assets/fonts/mazzard/light-italic/MazzardM-LightItalic.svg') format('SVG');
}

@font-face {
  font-family: 'MazzardM-Thin';
  src:
    url('@~assets/fonts/mazzard/thin/MazzardM-Thin.woff2') format('WOFF2'),
    url('@~assets/fonts/mazzard/thin/MazzardM-Thin.woff') format('WOFF'),
    url('@~assets/fonts/mazzard/thin/MazzardM-Thin.eot') format('EOT'),
    url('@~assets/fonts/mazzard/thin/MazzardM-Thin.svg') format('SVG');
}
