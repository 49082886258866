// Base styles

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: padding-box;
  border: $card-border-width solid $card-border-color;
  padding: 30px;

  @include border-radius($card-border-radius);

  @if $enable-shadows {
    @include box-shadow(0 10px 30px 5px rgba($black, 0.75));
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    margin: -$card-border-width;
    border-radius: inherit;

    @include gradient-y(#886E2C, #362804);
  }

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {

    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {

    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }

  &.account {
    border-radius: 20px;
    background-color: $card-account-bg;
    height: 100%;
    padding: 50px;

    &::before {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      padding: 20px;
    }
  }

  &.with-circle {
    margin-top: 50px;

    .card-body {
      padding-top: 50px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 80px;
      height: 80px;
      border-radius: 80px;
      position: absolute;
      top: -40px;
      right: 45px;

      @include gradient-bg($primary);

      @if $enable-shadows {
        // @include box-shadow(0 0 50px 20px rgba($primary, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        // stylelint-disable-next-line scss/function-no-unknown
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-yiq($primary), $black, 15%), 0.5);
      }

      &.circle-transparent {
        background: none !important;
      }
    }
  }

  &.with-button {

    .card-body {
      padding-bottom: 30px;
    }
  }

  &.with-rank {

    .card-body {
      padding-top: 50px;
      padding-right: 50px;
      overflow: hidden;
      position: relative;

      .card-rank {

        .primary {
          color: $primary;
          opacity: 0.5;
          font-size: 160px;
          font-weight: bold;
          position: absolute;
          right: -15px;
          top: -45px;
        }

        .secondary {
          color: $gray-800;
          opacity: 0.5;
          font-size: 60px;
          font-weight: 900;
          text-transform: uppercase;
          transform: rotate(270deg);
          position: absolute;
          right: -70px;
          top: 180px;
        }
      }
    }
  }

  &.with-rank--wide {

    .card-body {
      padding-right: 120px;
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-y $card-spacer-x;
}

.card-block {
  background-color: $card-plate-bg;
  padding: 24px;
  border-radius: 20px;

  button {
    background-color: $card-plate-button-bg;
  }

  .plate-text {
    background-color: $card-plate-text-bg;
    border-radius: 10px;
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: ($card-spacer-y / -2);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {

  &:hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

.card-button {
  position: absolute;
  right: 0.5rem;
  bottom: -1rem;
}

// Optional textual caps

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {

    .list-group-item:first-child {
      border-top: 0;
    }
  }

  &.accordion-link {
    font-size: $font-size-lg;
    font-weight: 600;
    color: $primary;
    cursor: pointer;
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}

// Header navs

.card-icon {
  position: absolute;
  top: -25px;
  right: 60px;

  img {
    width: 50px;
    height: 50px;
  }

  &.icon-full {
    right: 45px;
    top: -40px;

    img {
      width: 80px;
      height: 80px;
    }
  }

  &.icon-text {
    line-height: 80px;
    margin-top: 0;
    padding: 0;
    text-align: center;
    width: 80px;
    height: 80px;
    top: -40px;
    right: 45px;
    font-size: 1.5rem;
    font-weight: 700;
    color: $gray-900;
  }
}

.card-header-tabs {
  margin-right: ($card-spacer-x / -2);
  margin-bottom: -$card-spacer-y;
  margin-left: ($card-spacer-x / -2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: ($card-spacer-x / -2);
  margin-left: ($card-spacer-x / -2);
}

// Card image

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch

  @include border-radius($card-inner-border-radius);
}

// Card image caps

.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch

  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch

  @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;

      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

// Card groups

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.

  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;

    // The child selector allows nested `.card` within `.card-group`
    // to display properly.

    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {

        &:first-child {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            border-top-right-radius: 0;
          }

          .card-img-bottom,
          .card-footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            border-top-left-radius: 0;
          }

          .card-img-bottom,
          .card-footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .card-img-top,
          .card-header {
            @include border-top-radius($card-border-radius);
          }

          .card-img-bottom,
          .card-footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child, :last-child, :only-child) {
          @include border-radius(0);

          .card-img-top,
          .card-img-bottom,
          .card-header,
          .card-footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}

// Columns

.card-columns {

  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

// Accordion

.accordion {

  .card:not(:first-of-type, :last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  .card:not(:first-of-type) {

    .card-header:first-child {
      border-radius: 0;
    }
  }

  .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
