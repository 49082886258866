#account-navigation {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .account-link {
    text-decoration: none !important;
  }

  .sub-menu-item {
    display: flex !important;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .label {
      display: flex;
      align-items: center;
      text-decoration: none !important;
      gap: 10px;

      .text {
        display: flex;
        align-items: center;
        text-decoration: none !important;
        gap: 4px;
      }
    }

    .action-icon {
      opacity: 0;
      transition: opacity 300ms linear;
    }

    &:hover {

      .action-icon {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(lg) {

  #account-navigation {
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    gap: 5px;

    .action-icon {
      display: none;
    }

    .sub-menu-item {
      border-radius: 20px;
      padding-left: 15px;
      padding-right: 15px;

      .label {

        .text {
          display: none;
        }
      }
    }
  }
}
