#history {

  .filter-select {
    padding-left: 0;
    padding-right: 0;
    border: none;
    font-weight: bold;
    border-radius: 0;
  }
}
