#profile {

  .heading,
  .profile-block {
    width: 100%;
    max-width: 750px;
  }

  .tg-block {
    width: 100%;
    max-width: 500px;
  }

  .line {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .label {
      font-weight: 600;
      font-size: 16px;
      color: $white;
    }

    .value {
      font-weight: 400;
      font-size: 16px;
      color: $gray-500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.link-with-button {
        margin-top: -2px;
      }
    }
  }

  .copy-link {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;

    > span {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
}
