// Override default variables before the import
@import 'custom-variables';
@import 'fonts';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap';

// Some functions from previous versions of Bootstrap
@import 'bootstrap/functions';

// Rewrite Bootstrap styles
@import 'bootstrap/imports';

// Utilities / helper classes
@import 'utils/imports';

// pages
@import 'pages/imports';

// Components
@import 'components/imports';

// Other libraries
@import '~slick-carousel/slick/slick';
@import 'libs/imports';

// Global style
@import 'global';
@import 'custom';
