.dropdown-menu {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid $gray-800;
}

.dropdown-toggle {
  display: flex;
  align-items: center;

  .caret {
    margin-left: 10px;
    transition: transform 300ms linear;
  }

  &.show {

    .caret {
      transform: rotate(-180deg);
    }
  }

  &::after {
    display: none;
  }
}
