#withdrawal {

  .withdrawal-block {
    width: 100%;
    max-width: 500px;
  }

  .converted-value {
    white-space: nowrap;
  }
}
