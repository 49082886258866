#user-stats {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;
  background-image: url('@~assets/logo/aurous.png');
  background-position-x: calc(100% + 25px);
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: 235px;

  .user-rank {
    display: inline-block;
    position: absolute;
    top: 40px;
    right: 30px;
    width: 90px;

    .rank-label {
      color: #5F5F5F;
      font-size: 14px;
    }

    .rank-value {
      color: $white;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .token-select {
    padding-left: 0;
    padding-right: 0;
    border: none;
    font-weight: bold;
    border-radius: 0;

    &.btn.show,
    &:active,
    &:hover,
    &:focus-visible {
      background: inherit;
      color: $link-hover;
    }
  }

  .info-block {
    margin-top: 70px;

    .info-line {
      margin-bottom: 10px;
    }

    .label {
      font-weight: bold;
    }

    .value {
      margin-left: 12px;
    }
  }

  .user-amount {
    display: inline-block;
    position: absolute;
    bottom: 40px;
    left: 35px;
    min-width: 150px;
    height: 80px;
    text-align: right;

    .tokens-amount {
      font-size: 26px;
      font-weight: bold;
      white-space: nowrap;
    }

    .tokens-balance {
      color: #5F5F5F;
    }
  }
}

@include media-breakpoint-down(lg) {

  #user-stats {
    background-size: 195px;

    .user-rank {
      right: 10px;
    }
  }
}
