#account-mobile-navigation {

  .account-menu-select {
    justify-content: space-between;
    width: 100%;
    background-color: $card-plate-bg;
    border: 0;
  }

  .dropdown-menu {

    .icon-holder {
      text-align: center;
      width: 20px;
    }
  }
}
