#account-layout {
  display: flex;
  gap: 15px;

  .side-bar {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    flex: 1;
    gap: 15px;
  }

  .content {
    flex: 1;
  }
}

@include media-breakpoint-down(lg) {

  #account-layout {
    flex-direction: column;

    .side-bar {
      max-width: inherit;
    }
  }
}
