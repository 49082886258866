.root {

  &.transparent {
    background: transparent !important;
    backdrop-filter: blur(0);
  }
}

.navLink {
  display: inline-block !important;
  cursor: pointer;
}
