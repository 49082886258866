/* ###### 9.4 Margin   ###### */

@mixin margin-variant($num, $viewport:'') {
  @if $viewport == '' {
    $viewport: '-';
  } @else {
    $viewport: '-' + $viewport + '-';
  }
}

@mixin do-margin-variant($viewport) {
  $num: 0;

  @while $num < 10 {
    @include margin-variant($num, $viewport);

    $num: $num + 1;
  }

  // margin: 10x to 100px with step of 5px
  $num: 10;

  @while $num <= 100 {
    @include margin-variant($num, $viewport);

    $num: $num + 5;
  }
}

// margin: 0px to 9px
$num: 0;

@while $num < 10 {
  @include margin-variant($num);

  $num: $num + 1;
}

// margin: 10x to 120px with step of 5px
$num: 10;

@while $num <= 120 {
  @include margin-variant($num);

  $num: $num + 5;
}

.mg-t-auto {
  margin-top: auto;
}

.mg-r-auto {
  margin-right: auto;
}

.mg-b-auto {
  margin-bottom: auto;
}

.mg-l-auto {
  margin-left: auto;
}

.mg-x-auto {
  margin: auto;
}

@mixin margin-breakpoint($viewport) {

  .mg-#{$viewport}-t-auto {
    margin-top: auto;
  }
  .mg-#{$viewport}-r-auto {
    margin-right: auto;
  }
  .mg-#{$viewport}-b-auto {
    margin-bottom: auto;
  }
  .mg-#{$viewport}-l-auto {
    margin-left: auto;
  }
  .mg-#{$viewport}-auto {
    margin: auto;
  }
  .mg-#{$viewport}-x-auto {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 480px) {
  @include do-margin-variant('xs');
  @include margin-breakpoint('xs');
}

@include media-breakpoint-up(sm) {
  @include do-margin-variant('sm');
  @include margin-breakpoint('sm');
}

@include media-breakpoint-up(md) {
  @include do-margin-variant('md');
  @include margin-breakpoint('md');
}

@include media-breakpoint-up(lg) {
  @include do-margin-variant('lg');
  @include margin-breakpoint('lg');
}

@include media-breakpoint-up(xl) {
  @include do-margin-variant('xl');
  @include margin-breakpoint('xl');
}
