#language-switcher {

  button {
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    color: $white;
    box-shadow: none !important;
    border: none !important;

    &.btn.show,
    &:active,
    &:hover,
    &:focus-visible {
      color: $link-hover !important;
      text-decoration: none;
      border: 0;
    }
  }

  .languages-list {
    min-width: 160px;
    float: right;
    top: 1rem !important;
  }
}
