@import '@~styles/custom-variables.scss';
@import '~bootstrap/scss/mixins/breakpoints';

$how-it-works-video-height: 170px;
$how-it-works-video-height-lg: 280px;
$how-it-works-video-width: 300px;
$how-it-works-video-width-lg: 500px;

.videoMain {
  height: fit-content;
  padding: 20px;
  background-image:
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU5IiBoZWlnaHQ9IjE1OSIgdmlld0JveD0iMCAwIDE1OSAxNTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wIDE1OC42MzVWMC4wMDA4MDg3MTZIMTU4LjYzNEwwIDE1OC42MzVaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXJfMTAzN18zNDUpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMTAzN18zNDUiIHgxPSI3OS4zMTciIHkxPSIxNTguNjM1IiB4Mj0iLTQ2LjcyOTEiIHkyPSIwLjAwMDgxMTAyOCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRkZCODAxIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iI0ZGQjgwMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo='),
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU5IiBoZWlnaHQ9IjE1OSIgdmlld0JveD0iMCAwIDE1OSAxNTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNTguODkxIDAuMzY1MjM0VjE1OC45OTlIMC4yNTY2NjhMMTU4Ljg5MSAwLjM2NTIzNFoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8xMDM3XzM0NykiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhcl8xMDM3XzM0NyIgeDE9Ijc5LjU3MzYiIHkxPSIwLjM2NTIzNCIgeDI9IjIwNS42MiIgeTI9IjE1OC45OTkiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iIzk4NkUwMyIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNGRjVDMDAiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K');
  background-repeat: no-repeat, no-repeat;
  background-position: top left, bottom right;
}

.videoHolder {
  padding: 2px;
  background-image:
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNzYiIHZpZXdCb3g9IjAgMCA2OCA3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTY2LjYwMTYgNzUuMzAwOEw2Ni42MDE2IDEuMDU2NDFMMC4xOTY2NTUgMS4wNTY0MSIgc3Ryb2tlPSIjQzQ2NzAyIiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg=='),
    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjgiIGhlaWdodD0iNzYiIHZpZXdCb3g9IjAgMCA2OCA3NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMDU0NjkgMC4wMDM5MDYyNVY3NC4yNDgzSDY3LjQ1OTYiIHN0cm9rZT0iI0ZGRDYwMCIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat, no-repeat;
  background-position: top right, bottom left;
  position: relative;
  z-index: 1;
  width: $how-it-works-video-width;
  height: $how-it-works-video-height;

  iframe {
    border: none;
    width: $how-it-works-video-width - 4px;
    height: $how-it-works-video-height - 4px;
  }

  @include media-breakpoint-up(md) {
    width: $how-it-works-video-width-lg * 0.75;
    height: $how-it-works-video-height-lg * 0.75;

    iframe {
      width: ($how-it-works-video-width-lg - 4px) * 0.75;
      height: ($how-it-works-video-height-lg - 4px) * 0.75;
    }
  }

  @include media-breakpoint-up(lg) {
    width: $how-it-works-video-width-lg;
    height: $how-it-works-video-height-lg;

    iframe {
      width: $how-it-works-video-width-lg - 4px;
      height: $how-it-works-video-height-lg - 4px;
    }
  }
}

@include media-breakpoint-down(md) {

  .gapForVideo {
    gap: 40px;
  }
}
