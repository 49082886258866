$font-family: 'MazzardM', 'Inter', 'Arial', system-ui, -apple-system, blinkmacsystemfont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Noto Sans', 'Liberation Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
$white: #FFF;
$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #6C757D;
$gray-700: #516376;
$gray-800: #3E4D5F;
$gray-900: #212529;
$black: #000;
$blue: #299FBA;
$indigo: #4D43B3;
$purple: #6F42C1;
$pink: #D63384;
$peach: #F3B658;
$red: #CD0000;
$orange: #AA5A10;
$yellow: #FFC107;
$green: #22C55E;
$brown: #AA5A10;
$teal: #20C997;
$cyan: #1C5D6C;
$dark-gold: #8D6B00; // CCA93A
$gold: #EAD177; // F6E494
$dark: #1D1D1D;
$link-hover: #FCD349;
$telegram: #08C !default;
$facebook: #4267B2 !default;
$twitter: #1DA1F2 !default;
$linkedin: #2867B2 !default;
$reddit: #FF4500 !default;
$medium-com: $white !default;
$youtube: #F00 !default;
$vk: #597DA3 !default;
$instagram: #C13584 !default;
$skype: #00AFF0 !default;
$viber: #8F5DB7 !default;
$whatsapp: #25D366 !default;
$bitcoin-talk: #F7931A !default;
$primary: $gold;
$success: $green;
$info: $cyan;
$warning: $brown;
$danger: $red;
$light: $gray-100;
$secondary: $gray-500;
$theme-colors: (
  'primary':    $primary,
  'secondary':  $secondary,
  'success':    $success,
  'info':       $info,
  'warning':    $warning,
  'danger':     $danger,
  'light':      $light,
  'gray':       $gray-700,
  'dark':       $dark,
  'pink':       $pink,
  'cyan':       $cyan,
  'orange':     $orange,
  'blue':       $blue,
  'purple':     $purple,
  'black':      $black,
);
$body-bg: $black;
$modal-backdrop-bg: rgba(28, 28, 28, 0.64);
$table-header-bg: #222;
$table-body-bg: #090909;
$table-border-color: #2C2C2C;
$btn-secondary-bg: rgba(26, 26, 26, 0.9);
$panel-bg: #0E0E0E;
$card-plate-bg: #202020;
$card-plate-button-bg: #2B2B2B;
$card-plate-text-bg: #3E3E3E;
$account-bg: rgba(20, 20, 20, 0.8);
$body-color: $white;
$text-muted: $gray-800;
$border-width: 2px;
$border-color: $primary;
$place1-color: #FCD55E;
$place2-color: #B6BAC5;
$place3-color: #ED7F34;
$selection-bg: #EAD177;
$selection-color: #0E0E0E;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4.5),
    7: ($spacer * 6),
  ),
  $spacers
);

// Grid variable
$grid-gutter-width: 1.5rem;

// Modal variables
$modal-content-bg: $dark;
$modal-content-color: $body-color;
$modal-content-border-width: 0;
$modal-content-border-color: $primary;
$modal-header-border-color: $primary;

// $modal-header-padding-y: $spacer * 1.5;
// $modal-header-padding-x: $spacer * 2.5;
// $modal-inner-padding: $spacer * 2.5;
// $font-weight-lighter:         lighter;
// $font-weight-light:           300;
// $font-weight-normal:          400;
$font-weight-medium: 500 !default;

// Heading variables
$headings-font-weight: 700;

// $font-weight-semibold:        600;
// $font-weight-bold:            700;
// $font-weight-bolder:          800;
// $font-weight-base:            $font-weight-semibold;
// $enable-shadows: false;
// $border-radius: 1.5rem;
// $border-radius-sm: $border-radius * 0.75;
// $border-radius-lg: $border-radius * 1.25;
$border-radius: 30px;
$table-border-radius: 20px;
$border-radius-sm: 1rem;
$border-radius-lg: 3rem;
$border-radius-xl: 5rem;
$border-radius-2xl: 8rem;
$border-radius-pill: 50rem;
$btn-border-width: 1px;
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.75rem;
$btn-padding-y-lg: 0.5rem;
$btn-padding-x-lg: 1rem;
$btn-close-color: $white;
$btn-font-size-lg: 1rem * 1.25;
$btn-line-height-lg: 1.5;

// $btn-font-weight: $font-weight-base;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;
$input-border-radius: $border-radius-lg;
$input-border-radius-sm: $border-radius;
$input-border-radius-lg: $border-radius-lg;

// $input-disabled-bg:           $gray-800;
$input-btn-padding-y: 0.8rem;
$input-btn-padding-x: 1.5rem;
$input-bg: $black;
$input-border-color: $primary;
$input-border-width: $border-width;
$input-color: $primary;
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;

// scss-docs-start input-group-variables
$input-group-addon-color: $primary;
$input-group-addon-bg: $black;

// scss-docs-end input-group-variables
// $global-padding: 1.25rem;
$card-bg: $panel-bg;
$card-account-bg: $account-bg;
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-color: $white;

// Progress bars
// scss-docs-start progress-variables
$progress-height: 50px;
$progress-bg: transparent;
$progress-border-radius: 10px;
$progress-box-shadow: none;
$progress-bar-color: #626262;
$progress-bar-bg: #626262;
$progress-bar-transition: all 0.6s ease; // scss-docs-end progress-variables

$table-striped-bg-factor: 0.25;
$table-striped-bg: rgba($gray-700, $table-striped-bg-factor);
$table-hover-color: $dark;
$table-hover-bg: $primary;
$table-border-width: 0;
$table-cell-padding-y: 1.5rem;
$table-cell-padding-x: 1rem;

// scss-docs-start grid-breakpoints
// Make sure $grid-breakpoints are applied also to ./breakpoints.ts file
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1050px,
  xl: 1200px,
  xxl: 1300px,
  xxxl: 1600px,
); // scss-docs-end grid-breakpoints

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 480px,
  md: 760px,
  lg: 1000px,
  xl: 1440px,
  xxl: 1920px
); // scss-docs-end container-max-widths

$table-color: $white;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light".
// Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Card variables
$card-group-margin: $grid-gutter-width / 2 !default;
$card-deck-margin: $card-group-margin !default;
$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Dropdown variables
$dropdown-border-radius: 13px;
$dropdown-border-radius: 13px;
$dropdown-color: $white;
$dropdown-bg: #222;
$dropdown-link-color: $white;
$dropdown-link-hover-color: $link-hover;
$dropdown-link-hover-bg: #383838;
$dropdown-link-active-color: $link-hover;
$dropdown-link-active-bg: #383838;
