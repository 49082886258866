#dashboard {

  .heading {
    max-width: 700px;
    width: 100%;
  }

  .subheading {
    max-width: 400px;
    width: 100%;
  }

  .notice {
    max-width: 700px;
  }
}

@include media-breakpoint-up(xxxl) {

  #dashboard {

    .deposit-wallet-address {

      .card-block {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }
}

@include media-breakpoint-down(xxxl) {

  #dashboard {

    .deposit-wallet-address {
      position: relative;
      padding-top: 120px;

      .card-block {
        padding-top: 100px;
      }

      .qrcode {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;

        .qrcode-holder {
          margin: 0 auto;
        }
      }
    }
  }
}
