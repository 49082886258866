.root {

  :global(.accordion) {
    --bs-accordion-border-width: 0;
    --bs-accordion-border-radius: 0;
  }

  :global(.accordion-item) {

    :global(.btn-link) {
      text-decoration: none;
    }
  }
}
