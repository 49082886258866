.navbar-nav {

  .nav-link {
    color: $white;

    &:hover {
      color: $link-hover;
    }
  }
}

.fixed-top {
  backdrop-filter: blur(5px);
}

.fluid-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.navbar-brand {
  min-width: 150px;
}

.navbar-toggler-holder {
  flex: 1;
  text-align: right;
}

.navbar-toggler {
  position: relative;
  background-color: transparent; // remove default button style
  border: none; // remove default button style
  height: 25px;
  margin-top: 15px;
  margin-right: 15px;

  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.navbar-toggler-icon {
  display: none;
}

.navbar-toggler-span {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: block;
  transition: transform 180ms cubic-bezier(0.04, 0.04, 0.12, 0.96);

  &.navbar-toggler-span-top {
    z-index: 4;

    span {
      top: 14px;
      transform: translateY(-4px);
    }
  }

  &.navbar-toggler-span-bottom {

    span {
      bottom: 14px;
      transform: translateY(4px);
    }
  }

  span {
    display: block;
    width: 30px;
    height: 2px;
    background: $primary;
    border-radius: 0.5px;
    position: absolute;
    right: 0;
    z-index: 1;
    transition: transform 160ms cubic-bezier(0.52, 0.16, 0.52, 0.84) 100ms;
  }
}

.opened .navbar-toggler-span {
  transition: transform 300ms cubic-bezier(0.04, 0.04, 0.12, 0.96) 100ms;
  transform: rotate(45deg);

  &.navbar-toggler-span-bottom {
    transform: rotate(-45deg);
  }

  span {
    transition: transform 180ms cubic-bezier(0.04, 0.04, 0.12, 0.96);
    transform: none;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.news-image {
  max-height: 300px;
}

.centered-block {
  max-width: 580px;
  margin: 0 auto;
}

// Alternate buttons
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;

  @include font-size($font-size);

  line-height: $line-height;

  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-size(
      $btn-padding-y - 0.125rem,
      $btn-padding-x - 0.125rem,
      $btn-font-size,
      $btn-line-height,
      $btn-border-radius
    );

    border-width: $border-width;
    border-color: $color;

    @include button-outline-variant($value);

    &.btn-lg {
      @include button-size(
        $btn-padding-y-lg - 0.125rem,
        $btn-padding-x-lg - 0.125rem,
        $btn-font-size-lg,
        $btn-line-height-lg,
        $btn-border-radius-lg
      );
    }
  }
}

.btn-primary {
  @include gradient-x($dark-gold, $gold);

  color: $white;
  border: none;

  // Animate the text when loading the element.
  // This animates it on page load and when hovering out.
  animation: btn-animation-rev 0.35s ease forwards;
  background-position-x: 0;

  &:hover {
    @include gradient-x($dark-gold, $gold);

    animation: btn-animation 0.35s ease forwards;
    background-position-x: 50%;
    color: $white;
  }

  &:active,
  &:focus {
    @include gradient-x($dark-gold, $gold);

    animation: btn-animation 0.35s ease forwards;
    background-position-x: 50%;
    color: $white !important;
  }

  &:active {

    &:focus {
      @include gradient-x($dark-gold, $gold);

      animation: btn-animation 0.35s ease forwards;
      background-position-x: 50%;
      color: $white;
    }
  }
}

.btn-secondary {
  background-color: $btn-secondary-bg;
  color: $white;
  border: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    @include gradient-x($dark-gold, $gold);

    color: $white !important;
  }
}

// Move the background and make it smaller. */
// Animation shown when entering the page and after the hover animation.
@keyframes btn-animation-rev {

  0% {
    background-size: 650%;
  }

  100% {
    background-size: 100%;
  }
}

// Move the background and make it larger.
// Animation shown when hovering over the text.
@keyframes btn-animation {

  0% {
    background-size: 100%;
  }

  100% {
    background-size: 650%;
  }
}

.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  z-index: 100;
}

.card .preloader {
  @include border-radius($card-border-radius);
}

.read-more {

  svg {
    transition: all 0.2s ease;
  }

  &:hover {
    text-decoration: none;

    svg {
      transition: all 0.2s ease;
      margin-left: 1rem !important;
    }
  }
}

.accordion-item {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;

    @include gradient-x(#DC8D17, #FCD349);
  }
}

.accordion-link {
  padding: 1.25rem 0;
  color: $white;
  width: 100%;
  font-weight: 700;
  font-size: 18px !important;
  transition: none;

  svg {
    font-size: 2rem;

    g {
      transition: transform 0.2s ease;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    color: $peach;

    svg {
      color: $peach;
    }
  }
}

.qrcode-holder {
  overflow: hidden;

  > svg {
    border-radius: 13px;
  }
}

.progress {
  position: relative;
  border-radius: 10px;
  border: 1px solid $progress-bar-color;
  min-width: 200px;

  .progress-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-transform: uppercase;
    color: $white;
  }

  &.progress-vertical {
    flex-direction: column;
    justify-content: end;
    width: 50px;
    height: 100%;
    min-width: inherit;

    .progress-bar {
      width: $progress-height !important;
      height: auto;
    }
  }
}

.cur-pointer {
  cursor: pointer;
}

.form-control:disabled {
  background-color: $gray-900;
}

.modal-backdrop {
  --bs-backdrop-opacity: 1;
  --bs-backdrop-bg: $modal-backdrop-bg;

  backdrop-filter: blur(10px);
  transform: translateZ(0);
  perspective: 1000px;
  backface-visibility: hidden;

  + .modal > .modal-dialog > .modal-content {
    --bs-modal-bg: transparent;
  }
}

.modal-content-bg {
  background-color: $gray-900;
  border-radius: $border-radius;
  padding: $spacer * 2;
  font-size: 18px;

  p {
    margin-bottom: 10px;
  }
}

.svg-inline--fa {

  &.interactive {
    cursor: pointer;
    transition: opacity 300ms ease-in;

    &:hover {
      opacity: 0.7;
    }
  }
}
