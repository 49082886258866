/* ###### 9.1 Background  ###### */

.bg-carbon {
  // TODO: Uncomment, when it will be required, or remove
  // background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../../images/bg-carbon.jpg');
  background-size: 300px;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: $gray-100;
}

.bg-gray-200 {
  background-color: $gray-200;
}

.bg-gray-300 {
  background-color: $gray-300;
}

.bg-gray-400 {
  background-color: $gray-400;
}

.bg-gray-500 {
  background-color: $gray-500;
}

.bg-gray-600 {
  background-color: $gray-600;
}

.bg-gray-700 {
  background-color: $gray-700;
}

.bg-gray-800 {
  background-color: $gray-800;
}

.bg-gray-900 {
  background-color: $gray-900;
}

.bg-white-1 {
  background-color: rgba(#FFF, 0.1);
}

.bg-white-2 {
  background-color: rgba(#FFF, 0.2);
}

.bg-white-3 {
  background-color: rgba(#FFF, 0.3);
}

.bg-white-4 {
  background-color: rgba(#FFF, 0.4);
}

.bg-white-5 {
  background-color: rgba(#FFF, 0.5);
}

.bg-white-6 {
  background-color: rgba(#FFF, 0.6);
}

.bg-white-7 {
  background-color: rgba(#FFF, 0.7);
}

.bg-white-8 {
  background-color: rgba(#FFF, 0.8);
}

.bg-white-9 {
  background-color: rgba(#FFF, 0.9);
}

.bg-black-1 {
  background-color: rgba(#000, 0.1);
}

.bg-black-2 {
  background-color: rgba(#000, 0.2);
}

.bg-black-3 {
  background-color: rgba(#000, 0.3);
}

.bg-black-4 {
  background-color: rgba(#000, 0.4);
}

.bg-black-5 {
  background-color: rgba(#000, 0.5);
}

.bg-black-6 {
  background-color: rgba(#000, 0.6);
}

.bg-black-7 {
  background-color: rgba(#000, 0.7);
}

.bg-black-8 {
  background-color: rgba(#000, 0.8);
}

.bg-black-9 {
  background-color: rgba(#000, 0.9);
}

.bg-indigo {
  background-color: $indigo;
}

.bg-purple {
  background-color: $purple;
}

.bg-pink {
  background-color: $pink;
}

.bg-orange {
  background-color: $orange;
}

.bg-teal {
  background-color: $teal;
}

.bg-purple-dark {
  background-color: darken($purple, 10%);
}

.bg-image-cover {
  background-size: cover;
}

.bg-image-center {
  background-position: center center;
}

.bg-overlay {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.8);
  }

  & > * {
    position: relative;
  }

  &.alpha-1::before {
    background: rgba($black, 0.1);
  }

  &.alpha-2::before {
    background: rgba($black, 0.2);
  }

  &.alpha-3::before {
    background: rgba($black, 0.3);
  }

  &.alpha-4::before {
    background: rgba($black, 0.4);
  }

  &.alpha-5::before {
    background: rgba($black, 0.5);
  }

  &.alpha-6::before {
    background: rgba($black, 0.6);
  }

  &.alpha-7::before {
    background: rgba($black, 0.7);
  }

  &.alpha-8::before {
    background: rgba($black, 0.8);
  }

  &.alpha-9::before {
    background: rgba($black, 0.9);
  }
}
