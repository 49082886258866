#react-doc-viewer {
  background-color: var(--bs-modal-bg);
}

#header-bar {
  display: none;
  background-color: var(--bs-modal-bg);
}

#file-name {
  color: $white;
  text-align: center;
}

#pdf-controls {
  background-color: var(--bs-modal-bg);
  justify-content: center;
  box-shadow: none;

  button,
  #pdf-download {
    background-color: $gold;
  }
}

#pdf-pagination-info {
  color: $white;
}

#pdf-page-wrapper {

  .react-pdf__Page {
    box-shadow: 0 0 10px 1px $gold;
  }
}
