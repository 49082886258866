#rank-system {
  display: block;

  .line-behind {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: calc(50% - 1px);
      display: inline-block;
      content: '';
      width: 0;
      height: 100%;
      border-left: 1px solid silver;
      z-index: 0;
    }
  }

  .place1 {
    color: $place1-color;
  }

  .place2 {
    color: $place2-color;
  }

  .place3 {
    color: $place3-color;
  }
}
