@import '@~styles/custom-variables.scss';

.footerLink {
  color: $white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  &:hover {
    color: $white;
  }

  svg {
    width: 20px !important;
  }
}
